export const SUCCESS = "SUCCESS";
export const FAIL = "FAIL";

export const LOGIN = "LOGIN";
export const GET_OTP = "GET_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const GET_TEAMS = "GET_TEAMS";
export const GET_ZONE_TEAMS = "GET_ZONE_TEAMS";
export const CREAT_TEAM = "CREAT_TEAM";

export const CREATE_USERS = "CREATE_USERS";
export const GET_USERS = "GET_USERS";
export const GET_CEO = "GET_CEO";
export const SET_USER_PERMISSIONS = "SET_USER_PERMISSIONS";
export const GET_GENERAL_MANAGERS = "GET_GENERAL_MANAGERS";
export const GET_REGIONAL_MANAGERS = "GET_REGIONAL_MANAGERS";
export const CHECK_EXISTING_EMPLOYEE_CODE = "CHECK_EXISTING_EMPLOYEE_CODE";
export const ASSIGN_OUTLETS_TO_USER = "ASSIGN_OUTLETS_TO_USER";

export const GET_ROLES = "GET_ROLES";
export const GET_STAFF_GROUPS = "GET_STAFF_GROUPS";

export const GET_OUTLETS = "GET_OUTLETS";
export const CREATE_OUTLET = "CREATE_OUTLET";
export const UPDATE_OUTLET = "UPDATE_OUTLET";

export const CREATE_FOOD_ITEMS = "CREATE_FOOD_ITEMS";
export const UPDATE_FOOD_ITEMS = "UPDATE_FOOD_ITEMS";
export const GET_FOOD_ITEMS = "GET_FOOD_ITEMS";
export const DELETE_FOOD_ITEMS = "DELETE_FOOD_ITEMS";

export const GET_CHILLED_FOOD_ITEMS = "GET_CHILLED_FOOD_ITEMS";
export const GET_HOT_FOOD_ITEMS = "GET_HOT_FOOD_ITEMS";
export const GET_CFTC_FOOD_ITEMS = "GET_CFTC_FOOD_ITEMS";

export const CREATE_TOILET = "CREATE_TOILET";
export const UPDATE_TOILET = "UPDATE_TOILET";
export const DELETE_TOILET = "DELETE_TOILET";
export const GET_TOILETS = "GET_TOILETS";
export const GET_TOILET_TYPES = "GET_TOILET_TYPES";

export const CREATE_FIRE_CHECKS = "CREATE_FIRE_CHECKS";
export const UPDATE_FIRE_CHECKS = "UPDATE_FIRE_CHECKS";
export const GET_FIRE_CHECKS = "GET_FIRE_CHECKS";

export const GET_ORGANISATION = "GET_ORGANISATION";

export const GET_ASSETS = "GET_ASSETS";
export const CREATE_ASSETS = "CREATE_ASSETS";
export const UPDATE_ASSET = "UPDATE_ASSET";
export const DELETE_ASSET = "DELETE_ASSET";
export const MAKE_ASSET_NOT_IN_USE = "MAKE_ASSET_NOT_IN_USE";
export const GET_ASSET_REASON_LIST = "GET_ASSET_REASON_LIST";

export const GET_OUTLET_DETAILS_BY_USER_ID = "GET_OUTLET_DETAILS_BY_USER_ID";

export const GET_FOOD_CATEGORIES = "GET_FOOD_CATEGORIES";
export const GET_HOT_FOOD_CATEGORIES = "GET_HOT_FOOD_CATEGORIES";
export const GET_CHILLED_FOOD_CATEGORIES = "GET_CHILLED_FOOD_CATEGORIES";
export const GET_CFTC_FOOD_CATEGORIES = "GET_CFTC_FOOD_CATEGORIES";

export const GET_CONTRACTORS = "GET_CONTRACTORS";
export const CREATE_CONTRACTORS = "CREATE_CONTRACTORS";
export const UPDATE_CONTRACTOR = "UPDATE_CONTRACTOR";
export const DELETE_CONTRACTOR = "DELETE_CONTRACTOR";
export const GET_CONTRACTOR_SUPPLIER_DETAILS =
  "GET_CONTRACTOR_SUPPLIER_DETAILS";

export const GET_COMPLIANCE_RANGE = "GET_COMPLIANCE_RANGE";

export const SET_WIZARD_STATUS = "SET_WIZARD_STATUS";

export const GET_ZONES = "GET_ZONES";

export const SUBMIT_QUERY_TO_ADMIN = "SUBMIT_QUERY_TO_ADMIN";

export const GET_SUPPLIER_TYPES = "GET_SUPPLIER_TYPES";
export const GET_CONTRACTORS_TYPES = "GET_CONTRACTORS_TYPES";

export const SET_GM_AM_PERMISSIONS = "SET_GM_AM_PERMISSIONS";

export const GET_LINE_MANAGERS = "GET_LINE_MANAGERS";

export const GET_GENERAL_MANAGER_STATS = "GET_GENERAL_MANAGER_STATS";
export const GET_ORGANISATION_CONFIGURATIONS =
  "GET_ORGANISATION_CONFIGURATIONS";

export const GET_MANAGERS_PERMISSIONS = "GET_MANAGERS_PERMISSIONS";

export const GET_CHECKLISTS = "GET_CHECKLISTS";
export const CREATE_CHECKLIST = "CREATE_CHECKLIST";
export const GET_CHECKLIST_ID = "GET_CHECKLIST_ID";
export const UPDATE_CHECKLIST_QUESTIONS = "UPDATE_CHECKLIST_QUESTIONS";
export const ASSIGN_OUTLETS_TO_CHECKLIST = "ASSIGN_OUTLETS_TO_CHECKLIST";
export const UN_ASSIGN_OUTLETS_FROM_CHECKLIST =
  "UN_ASSIGN_OUTLETS_FROM_CHECKLIST";
export const COPY_CHECKLIST = "COPY_CHECKLIST";
export const SCHEDULE_CHECKLIST = "SCHEDULE_CHECKLIST";
export const DELETE_CHECKLIST = "DELETE_CHECKLIST";
export const GET_CHECKLISTS_REPORT = "GET_CHECKLISTS_REPORT";
export const MARK_AS_ONDEMAND_CHECKLIST = "MARK_AS_ONDEMAND_CHECKLIST";

export const GET_DOCKETS = "GET_DOCKETS";
export const GET_TILES = "GET_TILES";
export const SET_TILES = "SET_TILES";

export const GET_ESCALATION_PERSONS_LIST = "GET_ESCALATION_PERSONS_LIST";

export const GET_BUSINESS_HOUR_DETAILS = "GET_BUSINESS_HOUR_DETAILS";

export const GET_PERFORMERS = "GET_PERFORMERS";

export const GET_TASK_GROUPS = "GET_TASK_GROUPS";
export const ORG_IMAGE_UPLOAD = "ORG_IMAGE_UPLOAD";

export const GET_ORGANISATION_LOGO = "GET_ORGANISATION_LOGO";
export const FETCH_EXCEL = "FETCH_EXCEL";

export const GET_SERVICE_LINE_CATEGORIES = "GET_SERVICE_LINE_CATEGORIES";
export const ADD_SERVICE_LINE_CATEGORY = "ADD_SERVICE_LINE_CATEGORY";
export const GET_SERVICE_LINE_ITEMS = "GET_SERVICE_LINE_ITEMS";
export const ADD_SERVICE_LINE_ITEM = "ADD_SERVICE_LINE_ITEM";
export const GET_SERVICE_LINE_ITEMS_REPORT = "GET_SERVICE_LINE_ITEMS_REPORT";

export const UPDATE_ASSET_IMAGE_REQUIRED_CONFIG =
  "UPDATE_ASSET_IMAGE_REQUIRED_CONFIG";

export const GET_USER_ROLES = "GET_USER_ROLES";
export const GET_DEACTIVATED_USERS = "GET_DEACTIVATED_USERS";

export const ORG_LEVEL_PROGRESS_SUMMARY = "ORG_LEVEL_PROGRESS_SUMMARY";
export const OUTLET_DRILL_DOWN_REPORT = "OUTLET_DRILL_DOWN_REPORT";
export const SEND_OUTLET_DRILL_DOWN_REPORT = "SEND_OUTLET_DRILL_DOWN_REPORT";

export const DEACTIVATE_USER = "DEACTIVATE_USER";
export const GET_CFTC_FOOD_HANDLINE_TYPE = "GET_CFTC_FOOD_HANDLINE_TYPE";
export const GET_CFTC_FOOD_HANDLINE_TYPE_FOR_TAB =
  "GET_CFTC_FOOD_HANDLINE_TYPE_FOR_TAB";
export const CFTC_UPDATE_FOOD_CATEGORY = "CFTC_UPDATE_FOOD_CATEGORY";
export const GET_SHIFTS = "GET_SHIFTS";
export const CREATE_SHIFTS = "CREATE_SHIFTS";
export const UPDATE_SHIFTS = "UPDATE_SHIFTS";
export const DELETE_SHIFTS = "DELETE_SHIFTS";
export const UPDATE_FOOD_TEMP_CONFIGS = "UPDATE_FOOD_TEMP_CONFIGS";
export const GET_FOOD_TEMP_CONFIGS = "GET_FOOD_TEMP_CONFIGS";
export const CREATE_TASK = "CREATE_TASK";
export const GET_OUTLET_DETAILS_BY_OUTLET_ID =
  "GET_OUTLET_DETAILS_BY_OUTLET_ID";

export const GET_AUDITS = "GET_AUDITS";
export const GET_AUDIT_BY_ID = "GET_AUDIT_BY_ID";
export const UPDATE_AUDIT_QUESTIONS = "UPDATE_AUDIT_QUESTIONS";
export const SCHEDULE_AUDIT = "SCHEDULE_AUDIT";
export const CREATE_AUDIT = "CREATE_AUDIT";
export const GET_AUDIT_CATEGORIES = "GET_AUDIT_CATEGORIES";
export const GET_HISTORY_AUDITS = "GET_HISTORY_AUDITS";
export const GET_AUDIT_REPORT = "GET_AUDIT_REPORT";
export const COPY_AUDIT = "COPY_AUDIT";
export const DELETE_AUDIT = "DELETE_AUDIT";

export const SEND_REPORT = "SEND_REPORT";

export const AUTOMATIC_SENSOR_HISTORY = "AUTOMATIC_SENSOR_HISTORY"
export const AUTOMATIC_SENSOR_INFO = "AUTOMATIC_SENSOR_INFO"